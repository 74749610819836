import React from "react";
import { Router } from "@reach/router";
import Header from "../components/header";

import Footer from "../components/footer";
import SeO from "../components/SEO";
import NotFoundPage from "./404";

import settings from "../components/settings.json";
import Other from "../components/other";

const OtherSearch = ({ data }) => {
  return (
    <div>
      <SeO
        title={`Searching for ${data.slug}`}
        description={`This is search result ${
          data.slug
        } with matched suggestions ${data.serverData.data?.searchByCatOrBusiness[0]?.suggestionrelated.replace(
          /\([^()]*\);/g,
          ""
        )}  `}
      />
      <Header
        path={data.path}
        searchSlug={data.slug}
        city={`${data.city}-${data.stateCode}`}
      />

      <Other
        otherData={data.serverData.data.searchByCatOrBusiness}
        loc={data.city + ", " + data.stateCode}
        slug={data.slug}
      />
      <Footer />
    </div>
  );
};

const other = ({ pageContext, serverData }) => {
  return (
    <Router>
      <OtherSearch
        path="/other/:slug/:city-:state"
        context={pageContext}
        data={serverData}
      />

      <NotFoundPage path="/other/*" />
    </Router>
  );
};
export default other;

export async function getServerData(props) {
  const slug = props.params["*"]?.split("/")[0].replace(/-/g, " ");
  const cityState = props.params["*"]?.split("/")[1];
  const city = cityState?.split("-")[0];
  const state = cityState?.split("-")[1];

  const query = props.query;
  let lat, long;

  if (Object.keys(query).length > 0) {
    const [latStr, longStr] = Object.keys(query)[0].split('-');
    lat = parseFloat(latStr);
    long = parseFloat(longStr);
  } else {
    // Default values if no data is present in the query
    lat = "";
    long = "";
  }

  try {
    const res = await fetch(settings.apiURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: `{
          searchByCatOrBusiness(name: "${slug}", location: "${city}", state_code: "${state}", latitude:"${lat}",longitude:"${long}"){
            id
            others
            suggestiontext
            suggestionrelated
            suggestionurls
            status
            certified
            name
            phone
            address
            city
            state
            zip
            
            message
            referred_by
            category
            company_logo
            latitude
            longitude
            description
            service_frequency
            days_open
            email
            images{
                id
                path
            }
            company_coupon
            company_ad
            advertise_begin_date
            review_link
            audio
            video
            category
            wal_pdf
            keywords
            website
            facebook
            instagram
            youtube
            twitter
            linkedin
            show_address
            show_ad
            distance
            company_ad
            review_link
            spotlight_link
            slug
          }
        }`,
      }),
    });
    if (!res.ok) {
      throw new Error(`Response failed`);
    }
    const data = {
      serverData: await res.json(),
      slug: slug,
      city: city,
      stateCode: state,
      path: props.url.split("/")[2],
    };

    return {
      props: data,
    };
  } catch (err) {
    return {
      status: 500,
      headers: {},
      props: {},
    };
  }
}
